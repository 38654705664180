import React from 'react'
import PropTypes from 'prop-types'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import GridListTile from '@material-ui/core/GridListTile'
import GridList from '@material-ui/core/GridList'
import { FormattedMessage } from 'react-intl'

const propTypes = { items: PropTypes.any }

const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around'
  },
  gridList: {
    // width: 500,
    // height: 450,
    // overflowY: 'auto'
  }
}

class ProjectsComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = { items: [] }
  }

  render () {
    return (
      <section id='projects' style={{ paddingTop: '100px' }}>
        <div className='section-title text-center wow fadeInDown'>
          <h2>
            <FormattedMessage id='projects' />
          </h2>
        </div>

        <div className='clearfix'>
          <div style={styles.root}>
            {this.props.items && (
              <GridList cols={4} padding={5} style={styles.gridList} cellHeight='auto'>
                {this.props.items &&
                  this.props.items.map((tile, key) => (
                    <GridListTile
                      key={key}
                      title={tile.name}
                      cols={parseInt(tile.columns || 1)}
                      rows={parseInt(tile.rows || 3)}
                    >
                      <ImageGallery
                        items={[{ original: tile.image ? tile.image[0].src : 'http://via.placeholder.com/500x500' }]}
                        showThumbnails={false}
                        showPlayButton={false}
                      />
                    </GridListTile>
                  ))}
              </GridList>
            )}
          </div>
        </div>
      </section>
    )
  }
}

ProjectsComponent.propTypes = propTypes
export default ProjectsComponent
