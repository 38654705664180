import React from 'react'
import { Admin } from 'react-admin'
import Menu from './components/CustomMenu'
import Login from './components/CustomLogin'
import Dashboard from './modules/dashboard'
// import customRoutes from './Routes';
import {
  Resources,
  firebaseConfig,
  trackedResources,
  authProvider,
  i18nProvider
} from './backend/dataProvider/firebase'
import { RestProvider, base64Uploader } from 'ra-data-firebase-client'

const dataProvider = base64Uploader(RestProvider(firebaseConfig, { trackedResources }))
const Administration = () => (
  <Admin
    locale='en'
    menu={Menu}
    loginPage={Login}
    dashboard={Dashboard}
    dataProvider={dataProvider}
    authProvider={authProvider}
    // customRoutes={customRoutes}
    title={'Equipe Technique Tunisienne'}
    i18nProvider={i18nProvider}
  >
    {Resources}
  </Admin>
)
export default Administration
