import { getMenu, getLocalization } from '../../firebase'
import actionType from '../constants'
export const loadMenu = () => {
  return dispatch => {
    dispatch({
      type: actionType.LOAD_MENU_REQUEST
    })
    getMenu()
      .then(menuItems => {
        dispatch({
          type: actionType.LOAD_MENU_SUCCESS,
          payload: Object.values(menuItems.val())
            .filter(item => item.visible === true)
            .sort((a, b) => a.order > b.order)
        })
      })
      .catch(error => {
        dispatch({
          type: actionType.LOAD_MENU_FAILED,
          payload: error
        })
      })
  }
}
export const loadLocalization = () => {
  return dispatch => {
    dispatch({
      type: actionType.LOAD_LOCALIZATION_REQUEST
    })
    getLocalization()
      .then(localeSet => {
        dispatch({
          type: actionType.LOAD_LOCALIZATION_SUCCESS,
          payload: Object.values(localeSet.val())
        })
      })
      .catch(error => {
        dispatch({
          type: actionType.LOAD_LOCALIZATION_FAILED,
          payload: error
        })
      })
  }
}

export const changeLocale = locale => {
  return dispatch => {
    dispatch({
      type: actionType.CHANGE_LOCALE,
      payload: locale
    })
  }
}
