import _ from 'lodash'
import actionType from '../constants'
let initialState = {
  menuItems: [],
  localeSet: [],
  locale: 'en'
}

export default (state = initialState, action) => {
  let newState = _.merge({}, state)
  switch (action.type) {
    case actionType.LOAD_MENU_SUCCESS:
      newState.menuItems = action.payload
      return newState
    case actionType.CHANGE_LOCALE:
      newState.locale = action.payload
      return newState
    case actionType.LOAD_LOCALIZATION_SUCCESS:
      newState.localeSet = action.payload
      return newState
    default:
      return state
  }
}
