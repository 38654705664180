import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { changeLocale } from '../actions/MenuAction'
import { connect } from 'react-redux'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'

class HomeComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  render () {
    return (
      <Fragment>
        <header id='navigation' className='navbar-fixed-top'>
          <div className='container-fluid'>
            <div className='navbar-header'>
              <button type='button' className='navbar-toggle' data-toggle='collapse' data-target='.navbar-collapse'>
                <span className='sr-only'>Toggle navigation</span>
                <span className='icon-bar' />
                <span className='icon-bar' />
                <span className='icon-bar' />
              </button>
              <h1 className='navbar-brand'>
                <a href='#body'>
                  <img src='https://www.dropbox.com/s/x0hu0gh3z3fmlja/LOGO.svg?raw=1' width='100' />
                </a>
              </h1>
            </div>
            <nav className='collapse navigation navbar-collapse navbar-right' role='navigation'>
              <ul id='nav' className='nav navbar-nav'>
                {/* <li className="current">
                  <a href="#home">Home</a>
                </li> */}
                {this.props.items &&
                  this.props.items.map((item, key) => (
                    <li key={key}>
                      <a data-scroll href={`#${item.route}`}>
                        <FormattedMessage id={item.route} defaultMessage={item.route} />
                      </a>
                    </li>
                  ))}
                <li>
                  <a role='button' onClick={e => this.props.changeLocale('en')}>
                    <img src='https://image.flaticon.com/icons/svg/323/323329.svg' width='25' />
                  </a>
                </li>
                <li>
                  <a role='button' onClick={e => this.props.changeLocale('fr')}>
                    <img src='https://image.flaticon.com/icons/svg/197/197560.svg' width='25' />
                  </a>
                </li>
                <li>
                  <a role='button' onClick={e => this.props.changeLocale('it')}>
                    <img src='https://image.flaticon.com/icons/svg/197/197626.svg' width='25' />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <section id='home'>
          <ImageGallery
            items={this.props.gallery.map(item => {
              return { original: item.image && item.image[0].src }
            })}
            showThumbnails={false}
            showPlayButton={false}
            showFullscreenButton={false}
            showBullets
            autoPlay
          />
        </section>
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    locale: state.Menu.locale
  }
}

const connectElement = { changeLocale }

HomeComponent.propTypes = {
  items: PropTypes.any,
  changeLocale: PropTypes.func,
  gallery: PropTypes.any
}
HomeComponent.defaultProps = { gallery: [] }

export default connect(
  mapStateToProps,
  connectElement
)(HomeComponent)
