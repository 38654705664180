import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

const propTypes = { items: PropTypes.any }
// isEnglish: PropTypes.bool, home: PropTypes.bool

class ContactUsComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = { name: '', phone: '', email: '', message: '', aux: this.props.items }
  }
  sendEmail () {}
  render () {
    return (
      <Fragment>
        <section id='contact_us' style={{ paddingTop: '100px', paddingBottom: '100px' }}>
          <div className='container'>
            <div className='row'>
              <div className='section-title text-center wow fadeInDown'>
                <h2>
                  <FormattedMessage id='contact_us' />
                </h2>
                {/* <p>
                  Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Mauris blandit aliquet elit, eget
                  tincidunt.
                </p> */}
              </div>
            </div>
          </div>

          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-6 col-sm-12 wow fadeInLeft'>
                <div className='contact-form clearfix'>
                  <form action='index.html' method='post'>
                    <div className='input-field'>
                      <input type='text' className='form-control' name='name' placeholder='Your Name' required='' />
                    </div>
                    <div className='input-field'>
                      <input type='email' className='form-control' name='email' placeholder='Your Email' required='' />
                    </div>
                    <div className='input-field message'>
                      <textarea name='message' className='form-control' placeholder='Your Message' required='' />
                    </div>
                    <input type='submit' className='btn btn-blue pull-right' value='SEND MESSAGE' id='msg-submit' />
                  </form>
                </div>
              </div>

              <div className='col-md-6 col-sm-12 wow fadeInLeft'>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12778.49408218418!2d10.1802439!3d36.803576!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb1433c61a3429087!2sEquipe+Technique+Tunisienne!5e0!3m2!1sfr!2stn!4v1527113435345'
                  height='465'
                  style={{ width: '100%' }}
                  frameBorder='0'
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}

ContactUsComponent.propTypes = propTypes
export default ContactUsComponent
