import React from 'react'
import Filters from './filters'
import RichTextInput from 'ra-input-rich-text'
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  BooleanField,
  EditButton,
  TextInput,
  ChipField,
  TextField,
  BooleanInput
} from 'react-admin'

const list = props => (
  <List {...props} filters={<Filters />}>
    <Datagrid>
      <ChipField source='first_name' label='app.module.team.first_name' />
      <ChipField source='last_name' label='app.module.team.last_name' />
      <TextField source='position' label='app.module.team.position' />
      <BooleanField source='visible' label='app.module.team.visible' />
      <EditButton basePath='/team' label='' />
    </Datagrid>
  </List>
)

const edit = props => (
  <Edit {...props}>
    <SimpleForm redirect='list'>
      <TextInput source='first_name' label='app.module.team.first_name' resettable />
      <TextInput source='last_name' label='app.module.team.last_name' resettable />
      <TextInput source='position' label='app.module.team.position' resettable />
      <RichTextInput source='description' label='app.module.team.description' />
      <BooleanInput source='visible' label='app.module.team.visible' resettable />
    </SimpleForm>
  </Edit>
)
const create = props => (
  <Create name='app.module.services.create_service' {...props}>
    <SimpleForm redirect='list'>
      <TextInput source='first_name' label='app.module.team.first_name' resettable />
      <TextInput source='last_name' label='app.module.team.last_name' resettable />
      <TextInput source='position' label='app.module.team.position' resettable />
      <RichTextInput source='description' label='app.module.team.description' />
      <BooleanInput source='visible' label='app.module.team.visible' resettable />
    </SimpleForm>
  </Create>
)

export default { list, edit, create, name: 'team' }
