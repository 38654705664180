import React from 'react'
import Filters from './filters'
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  BooleanField,
  EditButton,
  TextInput,
  ChipField,
  ImageInput,
  ImageField
} from 'react-admin'

const list = props => (
  <List {...props} filters={<Filters />}>
    <Datagrid>
      <ChipField source='name' label='app.module.projects.name' />
      <BooleanField source='visible' label='app.module.projects.visible' />
      <EditButton basePath='/projects' label='' />
    </Datagrid>
  </List>
)

const edit = props => (
  <Edit {...props}>
    <SimpleForm redirect='list'>
      <TextInput source='name' label='app.module.projects.name' resettable />
      <ImageInput source='image' label='Related Image' accept='image/*' multiple>
        <ImageField source='src' title='title' />
      </ImageInput>
    </SimpleForm>
  </Edit>
)
const create = props => (
  <Create name='app.module.projects.create_service' {...props}>
    <SimpleForm redirect='list'>
      <TextInput source='name' label='app.module.projects.name' resettable />
      <ImageInput source='image' label='Related Image' accept='image/*' multiple>
        <ImageField source='src' title='title' />
      </ImageInput>
    </SimpleForm>
  </Create>
)

export default { list, edit, create, name: 'projects' }
