import _ from 'lodash'
import actionType from '../constants'
let initialState = {
  companySettings: []
}

export default (state = initialState, action) => {
  let newState = _.merge({}, state)
  switch (action.type) {
    case actionType.LOAD_COMPANYSETTINGS_SUCCESS:
      newState.companySettings = action.payload
      return newState
    default:
      return state
  }
}
