import DefaultIcon from '@material-ui/icons/BorderClear'
import settings from '@material-ui/icons/Settings'
import localization from '@material-ui/icons/Translate'
import services from '@material-ui/icons/ShoppingBasket'
import clients from '@material-ui/icons/DeviceHub'
import team from '@material-ui/icons/SupervisorAccount'
import media from '@material-ui/icons/LocalMovies'
import menu from '@material-ui/icons/ViewCompact'
import projects from '@material-ui/icons/Layers'
import dashboard from '@material-ui/icons/Dashboard'
import assets from '@material-ui/icons/LibraryBooks'
import gallery from '@material-ui/icons/PermMedia'

export const SysIcons = {
  default: DefaultIcon,
  settings,
  localization,
  services,
  clients,
  team,
  media,
  menu,
  projects,
  dashboard,
  assets,
  gallery
}
