import { getAll } from '../../firebase'
import actionType from '../constants'
export const loadAll = () => {
  return dispatch => {
    dispatch({
      type: actionType.LOAD_ALL_REQUEST
    })
    getAll()
      .then(all => {
        dispatch({
          type: actionType.LOAD_ALL_SUCCESS,
          payload: all.val()
        })
      })
      .catch(error => {
        dispatch({
          type: actionType.LOAD_ALL_FAILED,
          payload: error
        })
      })
  }
}
