import React from 'react'
import Filters from './filters'
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  BooleanField,
  EditButton,
  TextInput,
  ChipField,
  BooleanInput
} from 'react-admin'

const list = props => (
  <List {...props} filters={<Filters />}>
    <Datagrid>
      <ChipField source='name' label='app.module.clients.name' />
      <BooleanField source='visible' label='app.module.clients.visible' />
      <EditButton basePath='/clients' label='' />
    </Datagrid>
  </List>
)

const edit = props => (
  <Edit {...props}>
    <SimpleForm redirect='list'>
      <TextInput source='name' label='app.module.clients.name' resettable />
      <TextInput source='logo_url' label='Logo URL' resettable />
      <BooleanInput source='visible' label='app.module.clients.visible' resettable />
    </SimpleForm>
  </Edit>
)
const create = props => (
  <Create name='app.module.services.create_service' {...props}>
    <SimpleForm redirect='list'>
      <TextInput source='name' label='app.module.clients.name' resettable />
      <TextInput source='logo_url' label='Logo URL' resettable />
      <BooleanInput source='visible' label='app.module.clients.visible' resettable />
    </SimpleForm>
  </Create>
)

export default { list, edit, create, name: 'clients' }
