import Menu from './Menu'
import Team from './Team'
import Tech from './Tech'
import Projects from './Projects'
import Services from './Services'
import CompanySettings from './CompanySettings'
import MainSlider from './MainSlider'
import Gallery from './Gallery'
import Clients from './Clients'
import About from './About'
import All from './All'
import { combineReducers } from 'redux'

export default combineReducers({
  Menu,
  CompanySettings,
  MainSlider,
  Team,
  Tech,
  Services,
  Projects,
  Gallery,
  Clients,
  About,
  All
})
