import React from 'react'
import { Filter, TextInput } from 'react-admin'
// import PropTypes from 'prop-types';

const Filters = props => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
    {/* <TextInput label="UserName" source="username" defaultValue="" /> */}
  </Filter>
)
export default Filters
// Filters.propTypes = {
//   resource: PropTypes.any,
// };
