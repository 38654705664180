import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

const propTypes = { items: PropTypes.any }

class ClientsComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  render () {
    return (
      <section id='clients' style={{ paddingTop: '100px' }}>
        <div className='container'>
          <div className='row'>
            <div
              className='section-title text-center wow fadeInDown animated'
              style={{ visibility: 'visible', animationName: 'fadeInDown' }}
            >
              <h2>
                <FormattedMessage id='clients' />
              </h2>
              {/* <p>
                Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Mauris blandit aliquet elit, eget
                tincidunt.
              </p> */}
            </div>
            <div id='allLogos'>
              {this.props.items &&
                this.props.items.map((item, key) => (
                  <article className='ARTICLE' key={key}>
                    <img className='topClientImage' src={item.logo_url} id='IMG_4' />
                    <img className='botClientImage' src={item.logo_url} alt='Adobe' />
                    <span className='textSP'>{item.name}</span>
                  </article>
                ))}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

ClientsComponent.propTypes = propTypes
export default ClientsComponent
