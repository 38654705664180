import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

const propTypes = { items: PropTypes.any }

class TeamComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  render () {
    return (
      <section id='team' style={{ paddingTop: '100px' }}>
        <div className='container'>
          <div className='row'>
            <div
              className='section-title text-center wow fadeInDown animated'
              style={{ visibility: 'visible', animationName: 'fadeInDown' }}
            >
              <h2>
                <FormattedMessage id='team' />
              </h2>
              {/* <p>
                Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Mauris blandit aliquet elit, eget
                tincidunt.
              </p> */}
            </div>
            <div
              className='col-md-12 col-sm-12 wow fadeInLeft animated'
              style={{ visibility: 'visible', animationName: 'fadeInLeft' }}
            >
              <div className='tst-item'>
                {this.props.items &&
                  this.props.items.map((item, key) => (
                    <div key={key} className='tst-single clearfix'>
                      <img src={item.avatar} alt='Client' className='img-circle' />
                      <div className='tst-content'>
                        <h3>{item.last_name + ' ' + item.first_name}</h3>
                        <span>{item.position}</span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

TeamComponent.propTypes = propTypes
export default TeamComponent
