import localization from './localization/'
import services from './services'
import clients from './clients'
import team from './team'
import menu from './menu'
import settings from './settings'
import projects from './projects'
import assets from './assets'

export default [menu, services, projects, clients, team, assets, localization, settings]
