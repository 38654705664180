import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Admin from './admin/index'
import App from './app/index'
import registerServiceWorker from './registerServiceWorker'

ReactDOM.render(
  <Router>
    <React.Fragment>
      <Route exact path='/' component={App} />
      <Route exact path='/admin/' component={Admin} />
      {/* <Route exact path="/admin" render={() => <Redirect to="/admin/" />} /> */}
    </React.Fragment>
  </Router>,
  document.getElementById('root')
)
registerServiceWorker()
