import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import { translate, changeLocale } from 'react-admin'
import withStyles from '@material-ui/core/styles/withStyles'
import compose from 'recompose/compose'

const styles = {
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' }
}

const LocalChange = ({ classes, locale, changeLocale, translate }) => (
  <CardContent>
    <Button
      variant='raised'
      className={classes.button}
      color={locale === 'en' ? 'primary' : 'default'}
      onClick={() => changeLocale('en')}
    >
      en
    </Button>
    <Button
      variant='raised'
      className={classes.button}
      color={locale === 'fr' ? 'primary' : 'default'}
      onClick={() => changeLocale('fr')}
    >
      fr
    </Button>
    <Button
      variant='raised'
      className={classes.button}
      color={locale === 'it' ? 'primary' : 'default'}
      onClick={() => changeLocale('it')}
    >
      it
    </Button>
  </CardContent>
)

const mapStateToProps = state => ({
  theme: state.theme,
  locale: state.i18n.locale
})

LocalChange.propTypes = {
  classes: PropTypes.any,
  locale: PropTypes.any,
  changeLocale: PropTypes.any,
  translate: PropTypes.any
}
export default compose(
  connect(
    mapStateToProps,
    {
      changeLocale
    }
  ),
  translate,
  withStyles(styles)
)(LocalChange)
