import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardHeader from '@material-ui/core/CardHeader'
import { EditButton, TextField } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'
import CardMedia from '@material-ui/core/CardMedia'

const styles = {
  card: {
    width: '32%',
    margin: '0.5em',
    display: 'inline-block',
    verticalAlign: 'top'
  },
  media: {
    height: 140
  }
}
const GalleryField = ({ ids, data, basePath, classes }) => (
  <div style={{ margin: '1em' }}>
    {ids.map(id => (
      <Card key={id} className={classes.card}>
        <CardHeader
          title={<TextField record={data[id]} source='name' />}
          subheader={<TextField record={data[id]} source='type' />}
        />
        <CardMedia className={classes.media} image={data[id].image[0].src} title='Contemplative Reptile' />
        <CardActions>
          <EditButton resource='gallery' basePath={basePath} record={data[id]} label='' />
        </CardActions>
      </Card>
    ))}
  </div>
)
GalleryField.defaultProps = {
  data: {},
  ids: []
}
GalleryField.propTypes = { ids: PropTypes.any, data: PropTypes.any, basePath: PropTypes.any, classes: PropTypes.any }
export default withStyles(styles)(GalleryField)
