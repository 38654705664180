import React from 'react'
import { AuthProvider } from 'ra-data-firebase-client'
import $ from '../../../modules'
import defaultMessages from '../../i18n/en'
import { Resource } from 'react-admin'

export const i18nProvider = locale => {
  if (locale === 'fr') {
    return import('../../i18n/fr').then(messages => messages.default)
  } else if (locale === 'it') {
    return import('../../i18n/it').then(messages => messages.default)
  }
  // Always fallback on english
  return defaultMessages
}

export const firebaseConfig = {
  apiKey: 'AIzaSyBlFvmtHSMX0CTVNl6BC9RPzMiu_xrKGCc',
  authDomain: 'etttn-81024.firebaseapp.com',
  databaseURL: 'https://etttn-81024.firebaseio.com',
  projectId: 'etttn-81024',
  storageBucket: 'etttn-81024.appspot.com',
  messagingSenderId: '847206419713',
  appId: '1:847206419713:web:28783cc0699ad8f74c5f00'
}

export const trackedResources = [
  { name: 'localization', isPublic: true },
  { name: 'services', isPublic: true },
  { name: 'clients', isPublic: true },
  { name: 'team', isPublic: true },
  { name: 'menu', isPublic: true },
  { name: 'settings', isPublic: true },
  { name: 'projects', isPublic: true },
  { name: 'gallery', isPublic: true },
  { name: 'users', isPublic: true }
]
export const Resources = $.map(
  module =>
    !module.subs ? (
      <Resource key={module.name} name={module.name} list={module.list} edit={module.edit} create={module.create} />
    ) : (
      module.subs.map(sub => (
        <Resource key={sub.name} name={sub.name} list={sub.list} edit={sub.edit} create={sub.create} />
      ))
    )
)
const authConfig = {
  userProfilePath: '/users/',
  userAdminProp: 'isAdmin'
}
export const authProvider = AuthProvider(authConfig)

// let database;

// export const firebaseInit = () => {
//   firebase.initializeApp(firebaseConfig);
//   database = firebase.database();
// };

// const _trackedResources = async () => {
//   // let _trackedResources;
//   const ref = await database.ref('/');
//   const value = await ref.once('value');
//   const val = await value.val();
//   // console.warn(val);
//   let output = Object.keys(val).map(item => ({
//     name: item,
//     isPublic: true
//   }));
//   console.warn(output);
//   return output;
// };
