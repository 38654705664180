import React from 'react'
// import { HashRouter, Route } from 'react-router-dom';
import App from './App'
import { Provider } from 'react-redux'
import configureStore from './configureStore'

class Frontend extends React.Component {
  render () {
    return (
      <Provider store={configureStore()}>
        <App />
      </Provider>
    )
  }
}

export default Frontend
