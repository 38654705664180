import React from 'react'
import Filters from './filters'
import RichTextInput from 'ra-input-rich-text'
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  EditButton,
  DisabledInput,
  LongTextInput,
  ChipField,
  FormDataConsumer,
  BooleanInput,
  SelectInput,
  RichTextField,
  TextField
} from 'react-admin'
// import { RichTextField } from '@material-ui/core';

const list = props => (
  <List {...props} filters={<Filters />}>
    <Datagrid>
      <ChipField source='id' label='app.common.id' />
      <RichTextField source='en' label='app.module.localization.english' />
      <RichTextField source='fr' label='app.module.localization.french' />
      <RichTextField source='it' label='app.module.localization.italian' />
      <TextField source='type' label='app.module.localization.type' />
      <EditButton basePath='/localization' label='' />
    </Datagrid>
  </List>
)

const edit = props => (
  <Edit {...props}>
    <SimpleForm redirect='list'>
      <DisabledInput source='id' label='app.common.id' />
      <BooleanInput label='app.module.localization.is_html' source='isHTML' />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          !formData.isHTML ? (
            <span style={{ display: 'inline-grid' }}>
              <LongTextInput source='en' label='app.module.localization.english' {...rest} resettable />
              <LongTextInput source='fr' label='app.module.localization.french' {...rest} resettable />
              <LongTextInput source='it' label='app.module.localization.italian' {...rest} resettable />
            </span>
          ) : (
            <span style={{ display: 'inline-grid' }}>
              <RichTextInput source='en' label='app.module.localization.english' {...rest} resettable />
              <RichTextInput source='fr' label='app.module.localization.french' {...rest} resettable />
              <RichTextInput source='it' label='app.module.localization.italian' {...rest} resettable />
            </span>
          )
        }
      </FormDataConsumer>
      <SelectInput
        source='type'
        choices={[{ id: 'TEXT', name: 'TEXT' }, { id: 'MENU', name: 'MENU' }]}
        optionText='name'
        optionValue='id'
        allowEmpty
        resettable
      />
    </SimpleForm>
  </Edit>
)
const create = props => (
  <Create name='Create a Translation' {...props}>
    <SimpleForm redirect='list'>
      <LongTextInput source='id' label='app.common.id' />
      <BooleanInput label='app.module.localization.is_html' source='isHTML' />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          !formData.isHTML ? (
            <span style={{ display: 'inline-grid', width: '100%' }}>
              <LongTextInput source='en' label='app.module.localization.english' {...rest} resettable />
              <LongTextInput source='fr' label='app.module.localization.french' {...rest} resettable />
              <LongTextInput source='it' label='app.module.localization.italian' {...rest} resettable />
            </span>
          ) : (
            <span style={{ display: 'inline-grid' }}>
              <RichTextInput source='en' label='app.module.localization.english' {...rest} resettable />
              <RichTextInput source='fr' label='app.module.localization.french' {...rest} resettable />
              <RichTextInput source='it' label='app.module.localization.italian' {...rest} resettable />
            </span>
          )
        }
      </FormDataConsumer>
      <SelectInput
        source='type'
        choices={[{ id: 'TEXT', name: 'TEXT' }, { id: 'MENU', name: 'MENU' }]}
        optionText='name'
        optionValue='id'
        allowEmpty
        resettable
      />
    </SimpleForm>
  </Create>
)

export default { list, edit, create, name: 'localization' }
