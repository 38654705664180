import React from 'react'
import Filters from './filters'
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  EditButton,
  ReferenceField,
  ReferenceInput,
  BooleanField,
  BooleanInput,
  SelectInput,
  // ReferenceManyField,
  // ChipField,
  TextField
} from 'react-admin'

const list = props => (
  <List {...props} filters={<Filters />}>
    <Datagrid>
      {/* <ChipField source="route" label="app.module.menu.route" /> */}
      <ReferenceField label='app.common.english' source='route' reference='localization'>
        <TextField source='en' />
      </ReferenceField>
      <ReferenceField label='app.common.frensh' source='route' reference='localization'>
        <TextField source='fr' />
      </ReferenceField>
      <ReferenceField label='app.common.italian' source='route' reference='localization'>
        <TextField source='it' />
      </ReferenceField>
      <BooleanField source='visible' label='app.module.menu.visible' />
      <EditButton basePath='/menu' label='' />
    </Datagrid>
  </List>
)

const edit = props => (
  <Edit {...props}>
    <SimpleForm redirect='list'>
      <ReferenceInput label='app.module.menu.id' source='route' reference='localization' filter={{ type: 'MENU' }}>
        <SelectInput optionText='id' optionValue='id' />
      </ReferenceInput>
      <BooleanInput source='visible' label='app.module.menu.visible' />
    </SimpleForm>
  </Edit>
)
const create = props => (
  <Create name='app.module.services.create_service' {...props}>
    <SimpleForm redirect='list'>
      <ReferenceInput label='app.module.menu.id' source='route' reference='localization' filter={{ type: 'MENU' }}>
        <SelectInput optionText='id' optionValue='id' />
      </ReferenceInput>
      <BooleanInput source='visible' label='app.module.menu.visible' />
    </SimpleForm>
  </Create>
)

export default { list, edit, create, name: 'menu' }
