import React from 'react'
import Filters from './filters'
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  RichTextField,
  EditButton,
  TextInput,
  ChipField,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  ImageInput,
  ImageField
} from 'react-admin'

const list = props => (
  <List {...props} filters={<Filters />}>
    <Datagrid>
      <ChipField source='name' label='app.module.services.name' />
      <RichTextField source='title' label='app.module.services.title' />
      <RichTextField source='description' label='app.module.services.description' />
      <EditButton basePath='/services' label='' />
    </Datagrid>
  </List>
)

const edit = props => (
  <Edit {...props}>
    <SimpleForm redirect='list'>
      <TextInput source='name' label='app.module.services.name' resettable />
      <ReferenceInput label='app.module.services.title' source='title' reference='localization'>
        <SelectInput optionText='id' optionValue='id' />
      </ReferenceInput>
      <ReferenceInput
        filter={{ isHTML: true }}
        label='app.module.services.description'
        source='description'
        reference='localization'
        allowEmpty
      >
        <SelectInput optionText='id' optionValue='id' allowEmpty resettable />
      </ReferenceInput>
      <ImageInput source='image' label='Related Image' accept='image/*' multiple>
        <ImageField source='src' title='title' />
      </ImageInput>
    </SimpleForm>
  </Edit>
)
const create = props => (
  <Create name='app.module.services.create_service' {...props}>
    <SimpleForm redirect='list'>
      <TextInput source='name' label='app.module.services.name' resettable />
      <ReferenceInput
        filter={{ isHTML: false }}
        label='app.module.services.title'
        source='title'
        reference='localization'
        allowEmpty
      >
        <AutocompleteInput optionText='id' optionValue='id' allowEmpty resettable />
      </ReferenceInput>
      <ReferenceInput
        filter={{ isHTML: true }}
        label='app.module.services.description'
        source='description'
        reference='localization'
        allowEmpty
      >
        <SelectInput optionText='id' optionValue='id' allowEmpty resettable />
      </ReferenceInput>
      <ImageInput source='image' label='Related Image' accept='image/*' multiple>
        <ImageField source='src' title='title' />
      </ImageInput>
    </SimpleForm>
  </Create>
)

export default { list, edit, create, name: 'services' }
