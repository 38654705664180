import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import LinearProgress from '@material-ui/core/LinearProgress'

// import MenuComponent from './lib/components/MenuComponent';
import HomeComponent from './lib/components/HomeComponent'
import TechComponent from './lib/components/TechComponent'
import ClientsComponent from './lib/components/ClientsComponent'
import ServiceComponent from './lib/components/ServiceComponent'
import ProjectsComponent from './lib/components/ProjectsComponent'
import ContactUsComponent from './lib/components/ContactUsComponent'
import FooterComponent from './lib/components/FooterComponent'
import { loadLocalization } from './lib/actions/MenuAction'
import { loadAll } from './lib/actions/index'
import PropTypes from 'prop-types'
import { init as fbConnector } from './firebase'
import { connect } from 'react-redux'
import TeamComponent from './lib/components/TeamComponent'
import AboutComponent from './lib/components/AboutComponent'
import { addLocaleData, IntlProvider } from 'react-intl'
import en from 'react-intl/locale-data/en'
import it from 'react-intl/locale-data/it'
import fr from 'react-intl/locale-data/fr'
addLocaleData(en)
addLocaleData(it)
addLocaleData(fr)
const connectElement = {
  loadAll,
  loadLocalization
}

const propTypes = {
  loadAll: PropTypes.any,
  all: PropTypes.any,
  locale: PropTypes.any
}
class App extends Component {
  constructor (props) {
    super(props)
    this.state = { open: false, isEnglish: true, isLoading: true }
  }
  componentDidMount () {
    fbConnector()

    this.props.loadAll()
  }

  render () {
    let { locale, all } = this.props
    for (let module in all) {
      all[module] = Object.values(all[module])
    }
    let { team, technologies, clients, projects, company_settings, about, services, gallery, menu, localization } = all
    const messages =
      localization &&
      locale &&
      localization.reduce((obj, item) => {
        obj[item.id] = item.isHTML ? item[locale] : item[locale]
        return obj
      }, {})
    console.warn(messages)
    // const messages = localeSet.reduce((obj, item) => {
    //   obj[item.id] = item.isHTML ? item[this.props.locale] : item[this.props.locale];
    //   return obj;
    // }, {});
    console.log('--------------------------', all)
    const CLib = {
      // home: <HomeComponent items={this.props.menu} />,
      team: <TeamComponent items={team && team.filter(item => item.visible)} />,
      technologies: <TechComponent items={technologies} />,
      clients: <ClientsComponent items={clients} />,
      projects: <ProjectsComponent items={projects} />,
      contact_us: <ContactUsComponent items={company_settings} />,
      who_we_are: <AboutComponent items={about} />,
      services: <ServiceComponent items={services} />
    }
    let _render =
      menu && menu.length ? (
        <IntlProvider locale={locale} messages={messages} textComponent={React.Fragment}>
          <Fragment>
            <HomeComponent items={menu} gallery={gallery} />
            {menu && _.orderBy(menu, ['order'], ['asc']).map(item => CLib[item.route])}
            <FooterComponent items={company_settings} />
          </Fragment>
        </IntlProvider>
      ) : (
        <LinearProgress />
      )

    return _render
  }
}

const mapStateToProps = state => {
  // console.log(state);
  return {
    menu: state.Menu.menu,
    locale: state.Menu.locale,
    localeSet: state.Menu.localeSet,
    all: state.All.all
  }
}
App.propTypes = propTypes

export default connect(mapStateToProps, connectElement)(App)
