import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { translate } from 'react-admin'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter, NavLink } from 'react-router-dom'
import modules from '../modules'
import { SysIcons } from '../backend/Icons'

const styles = () => ({
  root: {
    paddingLeft: 17
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%'
  },
  nested: {
    paddingLeft: 30
  }
})
class CustomMenu extends React.Component {
  constructor (props) {
    super(props)
    this.state = { collapsed: null }
  }
  collapse (module) {
    this.setState({ collapsed: this.state.collapsed === module ? null : module })
  }

  render () {
    const { onMenuClick, translate, classes } = this.props
    return (
      <List disablePadding>
        <ListItem
          button
          component={NavLink}
          to={'/'}
          onClick={onMenuClick}
          classes={{
            root: classes.root
          }}
        >
          <ListItemIcon>
            <SysIcons.dashboard />
          </ListItemIcon>
          <ListItemText inset primary={translate(`app.dashboard`)} />
        </ListItem>
        {modules.map((module, key) => {
          const LeftIcon = SysIcons[module.name] || SysIcons.default
          return (
            <React.Fragment key={key}>
              <ListItem
                button
                component={!module.subs ? NavLink : null}
                to={!module.subs ? module.name : ''}
                onClick={!module.subs ? onMenuClick : e => this.collapse(module.name)}
                classes={{
                  root: classes.root
                }}
              >
                <ListItemIcon>
                  <LeftIcon />
                </ListItemIcon>
                <ListItemText inset primary={translate(`app.${module.name}`)} />
                {module.subs && this.state.collapsed === module.name ? (
                  <ExpandLess />
                ) : module.subs && this.state.collapsed !== module.name ? (
                  <ExpandMore />
                ) : null}
              </ListItem>
              {module.subs && (
                <Collapse in={this.state.collapsed === module.name} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding>
                    {module.subs.map((submodule, key) => {
                      const SubLeftIcon = SysIcons[submodule.name] || SysIcons.default
                      return (
                        <ListItem
                          key={key}
                          button
                          component={NavLink}
                          to={submodule.name}
                          onClick={onMenuClick}
                          classes={{
                            root: classes.nested
                          }}
                        >
                          <ListItemIcon>
                            <SubLeftIcon />
                          </ListItemIcon>
                          <ListItemText inset primary={translate(`app.${submodule.name}`)} />
                        </ListItem>
                      )
                    })}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          )
        })}
      </List>
    )
  }
}

const enhance = compose(
  withRouter,
  connect(
    state => ({
      theme: state.theme,
      locale: state.i18n.locale
    }),
    {}
  ),
  translate
)
CustomMenu.propTypes = { onMenuClick: PropTypes.any, translate: PropTypes.any, classes: PropTypes.any }
export default enhance(withStyles(styles)(CustomMenu))
