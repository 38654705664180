import React from 'react'
import {
  List,
  // Datagrid,
  Edit,
  Create,
  SimpleForm,
  SelectInput, // EditButton,
  TextInput,
  // ChipField,
  ImageInput,
  ImageField
} from 'react-admin'
import GalleryField from './components/GalleryField'

const list = props => (
  <List {...props}>
    <GalleryField />
  </List>
)

const edit = props => (
  <Edit {...props}>
    <SimpleForm redirect='list'>
      <TextInput source='name' label='app.module.gallery.name' resettable />
      <SelectInput
        source='type'
        choices={[{ id: 'SLIDER', name: 'SLIDER' }]}
        optionText='name'
        optionValue='id'
        allowEmpty
        resettable
      />
      <ImageInput source='image' label='Related Image' accept='image/*' multiple>
        <ImageField source='src' title='title' />
      </ImageInput>
    </SimpleForm>
  </Edit>
)
const create = props => (
  <Create name='app.module.gallery.create_service' {...props}>
    <SimpleForm redirect='list'>
      <TextInput source='name' label='app.module.gallery.name' resettable />
      <SelectInput
        source='type'
        choices={[{ id: 'SLIDER', name: 'SLIDER' }]}
        optionText='name'
        optionValue='id'
        allowEmpty
        resettable
      />
      <ImageInput source='image' label='Related Image' accept='image/*' multiple>
        <ImageField source='src' title='title' />
      </ImageInput>
    </SimpleForm>
  </Create>
)

export default { name: 'gallery', edit, create, list }
