export default {
  LOAD_MENU_REQUEST: 'LOAD_MENU_REQUEST',
  LOAD_MENU_SUCCESS: 'LOAD_MENU_SUCCESS',
  LOAD_MENU_FAILED: 'LOAD_MENU_FAILED',

  LOAD_COMPANYSETTINGS_REQUEST: 'LOAD_COMPANYSETTINGS_REQUEST',
  LOAD_COMPANYSETTINGS_SUCCESS: 'LOAD_COMPANYSETTINGS_SUCCESS',
  LOAD_COMPANYSETTINGS_FAILED: 'LOAD_COMPANYSETTINGS_FAILED',

  LOAD_MAINSLIDER_REQUEST: 'LOAD_MAINSLIDER_REQUEST',
  LOAD_MAINSLIDER_SUCCESS: 'LOAD_MAINSLIDER_SUCCESS',
  LOAD_MAINSLIDER_FAILED: 'LOAD_MAINSLIDER_FAILED',

  LOAD_TEAM_REQUEST: 'LOAD_TEAM_REQUEST',
  LOAD_TEAM_SUCCESS: 'LOAD_TEAM_SUCCESS',
  LOAD_TEAM_FAILED: 'LOAD_TEAM_FAILED',

  LOAD_ALL_REQUEST: 'LOAD_ALL_REQUEST',
  LOAD_ALL_SUCCESS: 'LOAD_ALL_SUCCESS',
  LOAD_ALL_FAILED: 'LOAD_ALL_FAILED',

  LOAD_TECH_REQUEST: 'LOAD_TECH_REQUEST',
  LOAD_TECH_SUCCESS: 'LOAD_TECH_SUCCESS',
  LOAD_TECH_FAILED: 'LOAD_TECH_FAILED',

  LOAD_SERVICES_REQUEST: 'LOAD_SERVICES_REQUEST',
  LOAD_SERVICES_SUCCESS: 'LOAD_SERVICES_SUCCESS',
  LOAD_SERVICES_FAILED: 'LOAD_SERVICES_FAILED',

  LOAD_PROJECTS_REQUEST: 'LOAD_PROJECTS_REQUEST',
  LOAD_PROJECTS_SUCCESS: 'LOAD_PROJECTS_SUCCESS',
  LOAD_PROJECTS_FAILED: 'LOAD_PROJECTS_FAILED',

  LOAD_GALLERY_REQUEST: 'LOAD_GALLERY_REQUEST',
  LOAD_GALLERY_SUCCESS: 'LOAD_GALLERY_SUCCESS',
  LOAD_GALLERY_FAILED: 'LOAD_GALLERY_FAILED',

  LOAD_CLIENTS_REQUEST: 'LOAD_CLIENTS_REQUEST',
  LOAD_CLIENTS_SUCCESS: 'LOAD_CLIENTS_SUCCESS',
  LOAD_CLIENTS_FAILED: 'LOAD_CLIENTS_FAILED',

  LOAD_ABOUT_REQUEST: 'LOAD_ABOUT_REQUEST',
  LOAD_ABOUT_SUCCESS: 'LOAD_ABOUT_SUCCESS',
  LOAD_ABOUT_FAILED: 'LOAD_ABOUT_FAILED',

  LOAD_LOCALIZATION_REQUEST: 'LOAD_LOCALIZATION_REQUEST',
  LOAD_LOCALIZATION_SUCCESS: 'LOAD_LOCALIZATION_SUCCESS',
  LOAD_LOCALIZATION_FAILED: 'LOAD_LOCALIZATION_FAILED',

  CHANGE_LOCALE: 'CHANGE_LOCALE'
}
