import * as firebase from 'firebase'
import { firebaseConfig } from '../admin/backend/dataProvider/firebase'
// import MenuModel from './lib/models/Menu';
let database
export const init = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig)
  }
  database = firebase.database()
}

export const getAll = () => {
  return database.ref('/').once('value')
}

export const getMenu = () => {
  return database.ref('/menu').once('value')
}
export const getLocalization = () => {
  return database.ref('/localization').once('value')
}
export const getCompanySettings = () => {
  return database.ref('/company_settings').once('value')
}

export const getMainSlider = () => {
  return database.ref('/main_slider').once('value')
}

export const getTeam = () => {
  return database.ref('/team').once('value')
}

export const getTech = () => {
  return database.ref('/technologies').once('value')
}

export const getServices = () => {
  return database.ref('/services').once('value')
}

export const getProjects = () => {
  return database.ref('/projects').once('value')
}

export const getGallery = index => {
  return database.ref('/gallery').once('value')
}

export const getClients = index => {
  return database.ref('/clients').once('value')
}

export const getAbout = index => {
  return database.ref('/about').once('value')
}
