import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

const propTypes = { items: PropTypes.array }

class ServiceComponent extends React.Component {
  render () {
    return (
      <Fragment>
        <section id='services' style={{ paddingTop: '100px' }}>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='section-title text-center wow fadeInDown'>
                  <h2>
                    <FormattedMessage id='services' />
                  </h2>
                  {/* <p>
                    Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Mauris blandit aliquet elit, eget
                    tincidunt.
                  </p> */}
                </div>
              </div>
            </div>
            <div className='row'>
              {this.props.items &&
                this.props.items.map((item, key) => (
                  <div
                    key={key}
                    className={
                      key % 2 === 0 ? 'col-md-6 col-sm-12 wow fadeInLeft' : 'col-md-6 col-sm-12 wow fadeInRight'
                    }
                  >
                    <div className='media'>
                      <a href='#' className='pull-left'>
                        <img
                          src='https://image.flaticon.com/icons/svg/702/702797.svg'
                          className='media-object'
                          width='40'
                        />
                      </a>
                      <div className='media-body'>
                        <h3>{item.name}</h3>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}

ServiceComponent.propTypes = propTypes
export default ServiceComponent
