import React from 'react'

import {
  Edit,
  TextInput,
  FormTab,
  Create,
  TabbedForm,
  LongTextInput,
  BooleanInput,
  List,
  Datagrid,
  TextField,
  ImageInput,
  ImageField,
  EditButton,
  DateField,
  BooleanField
} from 'react-admin'
import withStyles from '@material-ui/core/styles/withStyles'
import LocalChange from './components/LocalChange'
const styles = {
  email: { width: 544 },
  address: { width: '99.5%' },
  zipcode: { display: 'inline-block', width: '15%' },
  postalbox: { display: 'inline-block', width: '15%', marginLeft: 20 },
  city: { display: 'inline-block', width: '34%', marginLeft: 20 },
  country: { width: '30%', display: 'inline-block', marginLeft: 20 },
  gmap_latitude: { width: '49%', display: 'inline-block' },
  gmap_longitude: { width: '49%', display: 'inline-block', marginLeft: 20 }
}

const list = withStyles(styles)(({ classes, ...props }) => (
  <List {...props}>
    <Datagrid>
      <BooleanField source='default' label='Default' />
      <TextField source='id' label='ID' />
      <DateField source='createdAt' label='Created at' />
      <DateField source='updatedAt' label='Updated at' />
      <EditButton basePath='/settings' label='' />
    </Datagrid>
  </List>
))

const edit = withStyles(styles)(({ classes, ...props }) => (
  <Edit {...props} redirect='edit'>
    <TabbedForm>
      <FormTab label='app.module.settings.general'>
        <BooleanInput label='app.module.settings.default' source='general.default' />
        <LocalChange />
      </FormTab>
      <FormTab label='app.module.settings.company'>
        <TextInput label='app.module.settings.company_name' source='company.name' />
        <TextInput label='app.module.settings.abbr' source='company.abbreviation' />
        <TextInput label='app.module.settings.ceo' source='company.ceo' />
        <ImageInput source='image' label='app.module.settings.logo' accept='image/*' multiple>
          <ImageField source='src' title='title' />
        </ImageInput>
      </FormTab>
      <FormTab label='app.module.settings.address'>
        <LongTextInput label='app.module.settings.street' source='address.street' formClassName={classes.address} />
        <LongTextInput label='app.module.settings.zipcode' source='address.zipcode' formClassName={classes.zipcode} />
        <LongTextInput label='app.module.settings.pbox' source='address.pbox' formClassName={classes.postalbox} />
        <LongTextInput label='app.module.settings.city' source='address.city' formClassName={classes.city} />
        <LongTextInput label='app.module.settings.country' source='address.country' formClassName={classes.country} />
        <LongTextInput
          label='app.module.settings.gmap_latitude'
          source='address.gmap_latitude'
          formClassName={classes.gmap_latitude}
        />
        <LongTextInput
          label='app.module.settings.gmap_longitude'
          source='address.gmap_longitude'
          formClassName={classes.gmap_longitude}
        />
      </FormTab>
    </TabbedForm>
  </Edit>
))

const create = withStyles(styles)(({ classes, ...props }) => (
  <Create name='nameeeee' {...props}>
    <TabbedForm>
      <FormTab label='app.module.settings.general'>
        <BooleanInput label='app.module.settings.default' source='general.default' />
        <LocalChange />
      </FormTab>
      <FormTab label='app.module.settings.company'>
        <TextInput label='app.module.settings.company_name' source='company.name' />
        <TextInput label='app.module.settings.abbr' source='company.abbreviation' />
        <TextInput label='app.module.settings.ceo' source='company.ceo' />
        <ImageInput source='image' label='app.module.settings.logo' accept='image/*' multiple>
          <ImageField source='src' title='title' />
        </ImageInput>
      </FormTab>
      <FormTab label='app.module.settings.address'>
        <LongTextInput label='app.module.settings.street' source='address.street' formClassName={classes.address} />
        <LongTextInput label='app.module.settings.zipcode' source='address.zipcode' formClassName={classes.zipcode} />
        <LongTextInput label='app.module.settings.pbox' source='address.pbox' formClassName={classes.postalbox} />
        <LongTextInput label='app.module.settings.city' source='address.city' formClassName={classes.city} />
        <LongTextInput label='app.module.settings.country' source='address.country' formClassName={classes.country} />
        <LongTextInput
          label='app.module.settings.gmap_latitude'
          source='address.gmap_latitude'
          formClassName={classes.gmap_latitude}
        />
        <LongTextInput
          label='app.module.settings.gmap_longitude'
          source='address.gmap_longitude'
          formClassName={classes.gmap_longitude}
        />
      </FormTab>
    </TabbedForm>
  </Create>
))

export default { list, edit, create, name: 'settings' }
