import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
// import { dangerouslySetInnerHTML } from '../utils/funcUtils';

class AboutComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  createMarkup (html) {
    return { __html: html }
  }

  render () {
    // let { item } = this.props;
    return (
      <Fragment>
        <section id='who_we_are' style={{ paddingTop: '100px' }}>
          <div className='container'>
            <div className='row'>
              <div className='section-title text-center wow fadeInUp'>
                <h2>
                  <FormattedMessage id='who_we_are' defaultMessage='who_we_are' />
                </h2>
              </div>

              {this.props.items &&
                this.props.items.map((item, key) => (
                  <div className='media' key={key}>
                    <FormattedMessage id='who_we_are_body'>
                      {txt => <div className='media-body' dangerouslySetInnerHTML={this.createMarkup(txt)} />}
                    </FormattedMessage>
                  </div>
                ))}

              <div className='about-us text-center wow fadeInDown'>
                <img
                  src='https://wac-cdn.atlassian.com/dam/jcr:51be4df5-1ffb-4a4d-9f44-0b84dad9de5e/hero-collaboration-partial.png?cdnVersion=kv'
                  alt='About Us'
                  className='img-responsive'
                />
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}

const propTypes = { items: PropTypes.any }

AboutComponent.propTypes = propTypes
export default AboutComponent
