import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

const propTypes = { items: PropTypes.array }

class TechComponent extends React.Component {
  render () {
    return (
      <Fragment>
        <section id='technologies' style={{ paddingTop: '100px' }}>
          <div className='container'>
            <div className='row'>
              <div className='section-title text-center wow fadeInUp'>
                <h2>
                  <FormattedMessage id='technologies' />
                </h2>
              </div>
              <div className='wow fadeInUp'>
                {this.props.items &&
                  this.props.items.map((item, key) => (
                    <div
                      key={key}
                      className='col-md-3 col-sm-6 col-xs-12 wow fadeInUp'
                      // data-wow-delay={(parseInt(key + 1) * 0.1).toString() + 's'}
                    >
                      <div className='pricing-table text-center'>
                        <div className='price'>
                          <span className='value'>
                            <img src={item.icon} height='70' />
                          </span>
                          <span className='plan'>{item.name}</span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}

TechComponent.propTypes = propTypes
export default TechComponent
