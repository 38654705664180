import React, { Fragment } from 'react'
// import PropTypes from 'prop-types';

const propTypes = {}

class FooterComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  render () {
    return (
      <Fragment>
        <footer id='footer' className='text-center'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='footer-logo wow fadeInDown'>
                  <img src='https://www.dropbox.com/s/x0hu0gh3z3fmlja/LOGO.svg?raw=1' width='180' alt='logo' />
                </div>
                <div className='footer-social wow fadeInUp'>
                  <h3>We are social</h3>
                  <ul className='text-center list-inline'>
                    <li>
                      <a href='#'>
                        <i className='fa fa-facebook fa-lg' />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <i className='fa fa-twitter fa-lg' />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <i className='fa fa-google fa-lg' />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <i className='fa fa-github fa-lg' />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='copyright'>
                  <p>
                    All rights reserved
                    <a href='#'> Equipe Technique Tunisienne</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </Fragment>
    )
  }
}

FooterComponent.propTypes = propTypes
export default FooterComponent
